import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { POST } from '../../services/request';
import { useToasts } from 'react-toast-notifications';
import { useUser, Types } from '../../context/user';
import Router from 'next/router';
import Link from 'next/link'
const Login = () => {
  const { dispatch: dispatchUser } = useUser();
  const { addToast } = useToasts();
  const [isPassword, setIsPassword] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmitLogin = async (data) => {
    try {
      const { data: { token, user } } = await POST('/auths/login', data)
      dispatchUser({
        type: Types.LOGIN,
        item: {
          token, user
        }
      })
      addToast('Đăng nhập thành công', { appearance: 'success' });
      Router.push('/');
    } catch ({ data }) {
      addToast(data.error, { appearance: 'error' });
    }
  }
  return <div>
    <div className="page-content-wrapper">
      <div className="top-products-area py-3">
        <div className="container login p-4">
          <form onSubmit={handleSubmit(onSubmitLogin)}>
            <h3 className="text-center text-orange">Đăng Nhập</h3>
            <div className="form-group">
              <label className="pb-1">Email / Số điện thoại<span className="text-danger"> (*)</span></label>

              <Controller
                control={control}
                name="username"
                rules={{
                  required: "Email / Số điện thoại không được để trống"
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Email / Số điện thoại"
                    value={value}
                    onChange={(e) => onChange(e.target.value?.toLowerCase()?.trim() || '')}
                  />
                )}
              />
              {errors.username && <p className="p-errors">{errors.username.message}</p>}
            </div>
            <div className="form-group pt-2">
              <label className="pb-1">Mật khẩu:<span className="text-danger"> (*)</span></label>
              <div className="passord-wrap d-flex align-items-center">
                <input type={isPassword ? "password" : "text"} className="form-control form-control-sm" placeholder="Mật khẩu"
                  {...register('password', { required: "Mật khẩu không được để trống" })} />
                <i className="fa fa-eye eye" onClick={() => setIsPassword(!isPassword)}></i>
              </div>
              {errors.password && <p className="p-errors">{errors.password.message}</p>}
            </div>
            <div className="footer text-center pt-4 d-flex">
              <div className="btn-login">
                <input type="submit" name="submit" className="btn bg-orange btn-md" value="Đăng Nhập" />
              </div>
              <Link href="/register" className="text-orange d-flex ml-4">Đăng ký</Link>
              <div className='ml-2'>
                <Link href="/forgot-password" className="text-orange d-flex">Quên mật khẩu</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Login